@font-face {
    font-family: regular;
    src: url('../fonts/TitilliumWeb-Regular.ttf');
  }
  
  .contactForm {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .contactForm > div h1 {
    font-size: 3rem;
    margin: 20px 0 0 0;
    color: white;
  }
  
  label > p {
    color: white;
  }
  
  .contactForm label {
    color: white;
    margin: 10px 50px 10px 50px;
  }
  
  .form_name,
  .form_region,
  .form_mail,
  .form_message {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-around;
  }
  .radio_buttons{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .radio_buttons label{
    display: flex;
    align-items: center;
    font-size: 1rem;
    width: 100%;
  }
  .radio_buttons input[type="radio"] {
    transform: scale(0.7);
    width: 10%;
  }
  .radio_buttons input{
    width: 10px;
  }
  .form_input {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  
  .form_input p {
    font-size: 1.5rem;
    margin: 10px 0 10px 0;
    font-family: regular;
  }
  
  .form_input input,
  .form_select{ 
    border: 1px solid rgb(99, 99, 99);
    border-radius: 3px;
    color: white;
    background-color: rgb(34, 34, 34);
    font-size: 1.1rem;
    outline: none;
    width: 25vw;
    height: 40px;
  }
  .form_input textarea { 
    resize: vertical;
    width: 50vw;
    height: 10vh;
    color: white;
    font-size: 1.2rem;
    background-color: rgb(34, 34, 34);
    min-height: 100px;
  }
  
  .form_input input:focus,
  .form_select:focus,
  .form_textarea:focus { 
    border: 1px solid rgb(255, 255, 255);
  }
  
  .form_submit_button {
    border-radius: 10px;
    border: none;
    color: rgb(40, 40, 40);
    background-color: rgb(255, 255, 255);
    width: 10vw;
    height: 5vh;
    font-family: t-black;
    font-size: 1.1rem;
    transition: .5s;
    margin-bottom: 20px;
  }
  
  @media screen and (max-width: 768px) {
    div.form_name,
    .form_region,
    .form_mail,
    .form_occupation, /* Nouveau style pour le champ "Occupation" */
    .form_message { /* Nouveau style pour le champ "Message" */
      display: flex!important;
      align-items: center;
      justify-content: flex-start!important;
      margin: 0;
      flex-direction: column;
    }
  
    .form_name label,
    .form_region label,
    .form_mail label,
    .form_occupation label, /* Nouveau style pour le champ "Occupation" */
    .form_message label { /* Nouveau style pour le champ "Message" */
      margin: 0;
    }
  
    .form_submit_button {
      width: 50vw;
      height: 5vh;
    }
    .contactForm label {
      font-size: 3.5vw;
    }
    .radio_buttons input[type="radio"] {
      transform: scale(0.6);
      width: 10%;
    }
    .form_input input,
    .form_select,
    .form_textarea { 
      width: 50vw;
      height: 3vh;
    }

    .form_input label p{
      font-size: 4.5vw;
      margin: 0;
    }
    .form_input textarea {
      width: 80vw;
      height: 10vh;
    }
  }
  