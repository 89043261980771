@font-face {
    font-family: regular;
    src: url('../fonts/TitilliumWeb-Regular.ttf');
  }
  @font-face {
    font-family: semiBold;
    src: url('../fonts/TitilliumWeb-SemiBold.ttf');
  }
  @font-face {
    font-family: bebas;
    src: url('../fonts/BebasNeue.ttf');
}
p{
    font-family: regular;
    margin: 30px;
}
span.bar {
  width: 90vw;
  height: 1px;
  background-color: #5e5e5e;
}
.home-background {
  z-index: -1;
  width: 100%;
  height: 100vh;
}

.home-background img {
  height: 100%;
  width: 100%;
}

.home-presentation-text {
  margin-top: 50px;
  position: absolute; 
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 10;
}
.home-presentation-text h1{
  font-family: Bebas, sans-serif;
  color: rgba(255, 255, 255, 0.865);
  margin-top: 0;
  font-size: 6vw;
}
.home-presentation-text h2{
  font-family: regular;
  font-size: 2vw;
  margin-bottom: 5vw;
  transition: .5s;
  
}
.home_info_button{
  border-radius: 10px;
  border: none;
  color: rgb(40, 40, 40);
  background-color: rgb(255, 255, 255);
  padding: 15px 30px 15px 30px;
  width: 18vw;
  height: 7vh;
  font-family: t-black;
  font-size: 1.3rem;
  transition: .5s;
  margin-bottom: 50px;
  text-decoration: none;
}

.home_info_button:hover{
  background-color: #66787f;
  color: rgb(255, 255, 255);
}
#home_misson {
  display: flex;
  margin: 50px 15px 20px 15px;
  justify-content: center;
  align-items: center;
}

#home_misson .home_mission_text {
  font-family: regular;
  width: 50%;
  padding: 0; 
  display: flex;
  flex-direction: column;
}
.home_mission_text p{
  font-size: 1.5rem;
  margin: 0 80px 0 0;
}
.home_mission_text h1{
  font-family: bebas, sans-serif;
  font-size: 2.5rem;
  line-height: 1.2;
}

#home_misson .home_mission_pic img {
  width: 33vw;
}
.home_mission_button{
  border-radius: 10px;
  border: none;
  color: white;
  background-color: #66787f;
  width: 18vw;
  height: 7vh;
  font-family: t-black;
  font-size: 1.3rem;
  transition: .5s;
  margin: 50px 0 0 0;
}
.home_mission_button:hover{
  border-radius: 10px;
  border: 1px solid rgba(0,0,0,0.5);
  color: rgb(40, 40, 40);
  background-color: white;
  width: 18vw;
  height: 7vh;
  font-family: t-black;
  font-size: 1.3rem;
  transition: .5s;
  margin: 50px 0 0 0;
}
#home_public {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 200px;
}

.home_public_cont {
  font-family: regular;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; 
  margin: 20px; 
  padding: 40px; 
  border-radius: 6px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  background-color: #ffffff; 
  width: calc(30% - 40px); 
  max-width: 300px; 
}

.public_picture img {
  width: 100%;
  height: auto;
  border-radius: 5px; 
}

.public_text {
  text-align: center;
}

.public_text h1 {
  margin-top: 20px;
  font-size: 2rem; 
}

.public_text p {
  margin: 10px 0 0 0;
  font-size: 1.3rem;
  color: #666;
}

.home_mission_button{
  border-radius: 10px;
  border: none;
  color: white;
  background-color: #66787f(40, 40, 40);
  width: 18vw;
  height: 7vh;
  font-family: t-black;
  font-size: 1.3rem;
  transition: .5s;
  margin: 50px 0 0 0;
}
.home_mission_button:hover{
  border-radius: 10px;
  border: 1px solid rgba(0,0,0,0.5);
  color: rgb(40, 40, 40);
  background-color: white;
  width: 18vw;
  height: 7vh;
  font-family: t-black;
  font-size: 1.3rem;
  transition: .5s;
  margin: 50px 0 0 0;
}

@media screen and (max-width: 768px) {
  .home-background {
    z-index: -1;
    width: 100%;
    height: 60vh;
    margin-bottom: 100px;
  }
  div#home_misson {
    display: flex!important;
    justify-content: center !important;
    flex-direction: column-reverse !important;
    align-items: center;
    text-align: center; 
  }

  .home_mission_text {
    width: 90%; 
    margin: 20px auto; 
    text-align: center; 
  }

  .home_mission_text h1 {
    font-size: 2.5rem; 
    margin-bottom: 5px; 
  }

  .home_mission_text p {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .home_mission_button {
    width: 50%;
    font-size: 1rem;
    margin-top: 20px; 
  }

  .home-public-cont {
    width: 90%; 
    margin: 20px auto; 
  }

  .home_mission_button:hover {
    width: 52%; 
  }
  .home-presentation-text {
    margin-top: 0px;
    position: absolute; 
    top: 50vh;
    left: 50%; 
    transform: translate(-50%, -50%);
    color: black;
    text-align: center;
    z-index: 10;
    width: 100%;
  }
  .home-presentation-text h1{
    width: 100%;
    margin-bottom: 15vh;
    font-family: bebas;
    font-size: 10vw;
    color: white;
    
  }
  .home-presentation-text h2{
    font-family: regular;
    padding-top: 50px!important;
    font-size: 6vw;
  }
  .home_info_button{
    border-radius: 10px;
    border: none;
    color: white;
    background-color: #66787f;
    width: 50vw;
    height: 7vh;
    font-family: t-black;
    font-size: 1.3rem;
    transition: .5s;
    margin: 0;
  }
  #home_misson .home_mission_text p, #home_misson .home_mission_text{
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;
    width: 90% !important;
    margin: 0 !important;
  }

  #home_misson .home_mission_text h1{
    color: black;
    display: flex;
    justify-content: center;
  }
  .home_mission_pic{
    width: 100% !important;
    margin-top: 100px !important;
    padding: 0!important;
  }
  .home_mission_pic img {
    width: 90vw!important;
  }
  .home_public_cont {
    width: 80%;
    max-width: none;
    margin-bottom: 20px;
    margin-top: 0;
    
  }
  .home_mission_button{
    width: 35vw;
  }
  .home_mission_button:hover{
    width: 37vw;
  }
  
  .floue .home-container {
    filter: blur(5px);
}
span.bar {
  width: 90vw;
  height: 1px;
  background-color: #5e5e5e;
}

.floue .home-container nav {
    filter: none; 
}
}