/* navBar.css */

@font-face {
    font-family: semiBold;
    src: url('../fonts/TitilliumWeb-SemiBold.ttf');
}
@font-face {
    font-family: regular;
    src: url('../fonts/TitilliumWeb-Regular.ttf');
}
@font-face {
    font-family: t-black;
    src: url('../fonts/TitilliumWeb-Bold.ttf');
}

nav {
    top: 0;
    width: 100%;
    height: 12vh;
    background-color: #66787f;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.543);
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.nav_logo_container img {
    width: 180px;
    padding-bottom: 10px;
}

.nav_links {
    width: 60%;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    z-index: 100000;
}

.nav_links.active {
    max-height: 100%; 
}

nav a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-family: semiBold;
    font-size: 1.6rem;
    transition: .3s;
}

nav a:hover {
    font-size: 1.5rem;
}

.nav_button_area button {
    border-radius: 10px;
    border: none;
    color: rgb(40, 40, 40);
    background-color: rgb(255, 255, 255);
    width: 15vw;
    height: 6vh;
    font-family: semiBold;
    font-size: 1.3rem;
    transition: .5s;
}

.nav_button_area button:hover {
    background-color: rgb(40, 40, 40);
    color: rgb(255, 255, 255);
}

.burger_menu {
    display: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
}
.contact_button{
    background-color: #66787f;
    background-color: none;
    border: none;
    outline: none;
    width: auto;
    color: white;
    font-size: 1.6rem;
}
@media screen and (max-width: 768px) {
    nav{
        box-shadow: none;
        display: flex!important;
        align-items: center!important;
        justify-content: space-between;
    }
    .nav_links {
        position: absolute;
        top: 11vh;
        left: 0;
        width: 100%;
        background-color: #66787f!important;
        height: 0vh;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        transition: .5s ease-in-out;
        z-index: 1;
    }

    .nav_links.active {
        height: 210px!important;
        padding: 0!important;
    }
    .nav_logo_container img {
        padding: 0 0 0 20px;
        width: 130px;
    }
    .burger_menu {
        display: block;
    }

    .nav_button_area {
        display: flex;
        align-items: center;
    }

    .nav_button_area button {
        display: none;
    }
    .close{
        display: none;
    }
    .burger_menu{
        padding: 0 20px 0 0;
    }
}
